/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        // Accessible Drop-down menu
        $('.menu-item').has('ul').attr('aria-haspopup', 'true');
        $('.nav-desktop').find( 'a' ).on( 'focus blur', function() {
          $( this ).parents( '.menu-item' ).toggleClass( 'focus' );
        } );

        // Search
        var hasClicked = false;
        $('.menu-header-search .search-submit').on('click', function(e) {
          if (hasClicked === true) {
            return;
          } else {
            hasClicked = true;
            e.preventDefault();
            $('.menu-header-search').toggleClass('search-open');
            $('.menu-header-search form input').toggleClass('search-open');
          }
        });

        $('.sub-header-nav .btn-search').on('click', function() {
          $('.search-form').toggleClass('search-open');
        });
        
        // Hero Carousel
        $("#hero-slider").owlCarousel({
          loop : true,
          singleItem : true,
          navigation : false,
          navigationText : false,
          rewindNav : true,
          pagination : false,
          autoPlay : 6000,
          touchDrag : false,
          mouseDrag : false, 
          animateIn : 'fadeIn',
          animateOut : 'fadeOut',
        });

        var $supportersCarousel = $("#supporters");
        
        // Supporters Carousel
        $supportersCarousel.owlCarousel({
          items : 3,
          itemsDesktop : [1199,3],
          navigation : true,
          navigationText : false,
          rewindNav : true,
          pagination : false,
          theme : "",
          afterInit: function() {
            
            $('.owl-controls').appendTo('.section-supporters');

            $supportersCarousel.on("keydown", function(e) {

                // first of all make sure we override the default keyboard behaviour
                // only for the wrapper and not everything inside it
                if ($(e.target).is("#supporters")) {

                    var code = e.keyCode || e.which;

                    if (code === 9) { // tab key detected

                        //... code now shown as it has not changed since the previous step

                    } else if (code === 39) {
                        // arrow right - next slide
                        $supportersCarousel.data('owlCarousel').next();
                    } else if (code === 37) {
                        // arrow left - previous slide
                        $supportersCarousel.data('owlCarousel').prev();
                    }

                }

            });

          }, 
          afterAction: function() {

            // owl carousel's afterAction callback is fired after initialisation
            // and each user interaction with the carousel

            $supportersCarousel.on("keydown", function(e) {

                // get the code of key pressed
                var code = e.keyCode || e.which;

                if (code === 9) { // tab key detected

                    // get all tabbable elements excluding all carousel's contents
                    var $tabbable = $(":tabbable").not("#supporters *");

                    // the index of the carousel among all tabbable elements on the page
                    var carouselAmongTabbable = $tabbable.index($(this));

                    // increment, to get the index of the next tabbable
                    var nextTabbableIndex = carouselAmongTabbable + 1;

                    // access the next tabbable element by its index
                    var $nextTabbable = $tabbable.eq(nextTabbableIndex);

                    // force focus to the next tabbable element

                    // simply $nextTabbable.focus(); doesn't work
                    // wrapping into setTimeout does the trick
                    setTimeout(function() {
                        $nextTabbable.focus();
                    });

                }

            });

            var owl = this.owl;

            $supportersCarousel.on("keydown", function(e) {

                var code = e.keyCode || e.which;

                if (code === 13) { // enter key detected

                    // enter - focus on first tabbable element in the visible slide
                    setTimeout(function() {
                        $supportersCarousel.find('.owl-item').eq(owl.currentItem).find(':tabbable').focus();
                    });

                }

            });
          },
        });

        // Venue Carousel
        $("#gallery").owlCarousel({
          items : 1,
          itemsDesktop : false,
          itemsDesktopSmall : false,
          itemsTablet: false,
          itemsTabletSmall: false,
          itemsMobile : false,
          navigation : true,
          navigationText : false,
          rewindNav : true,
          pagination : true,
          theme : "",
          autoHeight: true,
          afterInit: function() {
            $('.owl-controls').appendTo('.gallery');
          }, 
        });

        // Program Highlights Carousel
        $("#program-highlights").owlCarousel({
          items : 1,
          itemsDesktop : false,
          itemsDesktopSmall : false,
          itemsTablet: false,
          itemsTabletSmall: false,
          itemsMobile : false,
          navigation : true,
          navigationText : false,
          rewindNav : true,
          pagination : true,
          theme : "",
          autoHeight: true,
          afterInit: function() {
            $('.owl-controls').appendTo('.gallery');
          }, 
        });

        // Countdown Timer

        if (window.location.href.indexOf("?lang=fr") > -1) {
          $('.countdown').countdown('2017/11/5').on('update.countdown', function(event) {
            var $this = $(this).html(event.strftime(''
              + '<div class="months"><span class="time-label">Moi%!m</span> <span class="time-amount">%-m</span></div>'
              + '<div class="days"><span class="time-label">Jour%!d</span> <span class="time-amount">%-d</span></div>'
              + '<div class="hours"><span class="time-label">Heures</span> <span class="time-amount">%H</span></div>'
              + '<div class="minutes"><span class="time-label">Minutes</span> <span class="time-amount">%M</span></div>'));
          });
        } else {
          $('.countdown').countdown('2017/11/5').on('update.countdown', function(event) {
            var $this = $(this).html(event.strftime(''
              + '<div class="months"><span class="time-label">Month%!m</span> <span class="time-amount">%-m</span></div>'
              + '<div class="days"><span class="time-label">Day%!d</span> <span class="time-amount">%-d</span></div>'
              + '<div class="hours"><span class="time-label">Hours</span> <span class="time-amount">%H</span></div>'
              + '<div class="minutes"><span class="time-label">Minutes</span> <span class="time-amount">%M</span></div>'));
          });
        }

        // Mobile Navigation
        var clickOpen  = $('.btn-menu-open');
        var menu       = $('.nav-mobile');
        var overlay    = $('.overlay');
        var content    = $('body');

        function openMenu() {
          overlay.removeClass('menu-close');
          content.addClass('menu-open');
          overlay.addClass('menu-open');
          menu.addClass('menu-open');
        }

        function closeMenu() {
          content.removeClass('menu-open');
          overlay.removeClass('menu-open');
          menu.removeClass('menu-open');
          setTimeout ( function(){
            overlay.addClass('menu-close');
          }, 400);
        }

        clickOpen.on("click", openMenu);
        overlay.on("click", closeMenu);

        // Mobile Touch
        $('.nav-mobile .nav li:has(ul)').doubleTapToGo();

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
